import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import NavLinks from './NavLinks'
import $ from 'jquery'
import SD140_1 from '../images/SD140_1.png'
import SD160_1 from '../images/SD160_1.png'
import Independent_Control_Eng from '../images/Independent_Control_Eng.png'
import Independent_Control_Chi from '../images/Independent_Control_Chi.png'
import Surge_Protection from '../images/Surge_Protection.png'
import App_Interface_Control from '../images/App_Interface_Control.jpg'
import App_Interface_Connect from '../images/App_Interface_Connect.png'
import Easy_Installation from '../images/Easy_Installation.png'
import Schedule_Of_Power from '../images/Schedule_Of_Power.png'
import Control_Without_Limitation from '../images/Control_Without_Limitation.png'
import Voice_Assistant from '../images/Voice_Assistant.png'
import HomeText from '../texts/HomeText.json'

import EasyInstallation from './howItWork/EasyInstallation'
import ScheduleOfPower from './howItWork/ScheduleOfPower'
import VoiceAssistant from './howItWork/VoiceAssistant'

function changeElementSizeAndMargin() {
    $('.home-section-1').css('min-height', `${$(window).height()}px`)
    $('#home').css('margin-top', `-${$('.header').height()}px`)
}

function changeElementSizeAndMarginInAllConditions() {
    if ($('button.navbar-toggler').is(":visible")) {
        if ($('.navbar-collapse').hasClass('collapsing')) {
            $('.navbar-collapse').on('hidden.bs.collapse', function (e) {
                changeElementSizeAndMargin()
            })
        } else {
            changeElementSizeAndMargin()
        }
    } else {
        changeElementSizeAndMargin()
    }
}

const HomeSectionImageLeft = ({ img, children }) => (
    <div className="home-section-image-left">
        <div className="container">
            <div className="row">
                <div className="col-lg">
                    <img src={img.source} alt={img.alt} style={img.style} />
                </div>
                <div className="col-lg">
                    {children}
                </div>
            </div>
        </div>
    </div>
)

const HomeSectionImageRight = ({ img, children }) => (
    <div className="home-section-image-right">
        <div className="container">
            <div className="row">
                <div className="col-lg">
                    {children}
                </div>
                <div className="col-lg">
                    <img src={img.source} alt={img.alt} style={img.style} />
                </div>
            </div>
        </div>
    </div>
)

const HowItWorkItem = ({ img, title, content, buttonText, dialogId }) => (
    <div className="col-lg text-center" style={{ display: 'flex', flexDirection: 'column' }}>
        <img src={img} alt={title} />
        <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
            <h5>{title}</h5>
            <p>{content}</p>
            <div style={{ flexGrow: 1, display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }}>
                <button
                    type="button"
                    className="btn btn-primary"
                    style={{
                        backgroundColor: '#4cbcf0',
                        borderColor: '#4cbcf0',
                        borderRadius: '20px'
                    }}
                    onClick={() => {
                        $(`#${dialogId}`).modal('show')
                    }}
                >{buttonText}</button>
            </div>
        </div>
    </div>
)

const Home = ({ language, setLanguage }) => {
    useEffect(() => {
        changeElementSizeAndMarginInAllConditions()
        $(window).on('resize', function () {
            changeElementSizeAndMarginInAllConditions()
        })

        /* home navbar change bg color when expand/collapse */
        $('.navbar-collapse').on('show.bs.collapse', function (e) {
            if ($(window).scrollTop() > 0) { return }
            $('#home.navbar').addClass('bg-dark')
            $('#home.navbar').removeClass('bg-transparent')
        })
        $('.navbar-collapse').on('hidden.bs.collapse', function (e) {
            if ($(window).scrollTop() > 0) { return }
            $('#home.navbar').removeClass('bg-dark')
            $('#home.navbar').addClass('bg-transparent')
        })

        /* collapse navbar when scroll */
        $(window).scroll(function () {
            $('.navbar-collapse').collapse('hide')
        })
        return () => {
            // console.log('clean up effect')
        }
    })

    return (
        <div id="home">
            <div className="home-section-1">
                <header className="header fixed-top">
                    <NavLinks page="home" setLanguage={setLanguage} />
                </header>
                <div className="simple-slogan">
                    <h1>
                        Simple Devices
                        <br />
                        Simple Life
                    </h1>
                </div>
                {/* <div className="container no-gutters simple-slogan">
                    <div className="row align-items-center h-100">
                        <div className="col-sm-3">
                        </div>
                        <div className="col-sm-6">
                            <h2>Simple Devices</h2>
                            <h2>Simple Life</h2>
                        </div>
                        <div className="col-sm-3">
                        </div>
                    </div>
                </div> */}
            </div>
            <HomeSectionImageLeft
                img={{ source: SD140_1, alt: 'SD140' }}
            >
                <h4>{HomeText.sd140[language].header}</h4>
                <h4>{HomeText.sd140[language].title}</h4>
                <hr />
                <p>{HomeText.sd140[language].content}</p>
                <NavLink exact to="/product/sd140a" className="btn btn-outline-success">{HomeText.sd140[language].buttonText}</NavLink>
            </HomeSectionImageLeft>
            <HomeSectionImageRight
                img={{ source: SD160_1, alt: 'SD160' }}
            >
                <h4>{HomeText.sd160[language].header}</h4>
                <h4>{HomeText.sd160[language].title}</h4>
                <hr />
                <p>{HomeText.sd160[language].content}</p>
                <NavLink exact to="/product/sd160a" className="btn btn-outline-success">{HomeText.sd160[language].buttonText}</NavLink>
            </HomeSectionImageRight>
            <div className="home-feature">
                <div className="container">
                    <div className="row justify-content-center text-center">
                        <div className="col-5">
                            <h1>{HomeText.features[language].heading}</h1>
                            <hr />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg">
                            <h5>{HomeText.features[language].smartOutlets.header}</h5>
                            <p>{HomeText.features[language].smartOutlets.content}</p>
                            <h5>{HomeText.features[language].voiceControl.header}</h5>
                            <p>{HomeText.features[language].voiceControl.content}</p>
                            <h5>{HomeText.features[language].senseMode.header}</h5>
                            <p>{HomeText.features[language].senseMode.content}</p>
                        </div>
                        <div className="col-lg">
                            <h5>{HomeText.features[language].surgeProtection.header}</h5>
                            <p>{HomeText.features[language].surgeProtection.content}</p>
                            <h5>{HomeText.features[language].controlFromAnywhere.header}</h5>
                            <p>{HomeText.features[language].controlFromAnywhere.content}</p>
                        </div>
                    </div>
                </div>
            </div>
            <HomeSectionImageLeft
                img={{ source: language === 'eng' ? Independent_Control_Eng : Independent_Control_Chi, alt: HomeText.independentControl[language].header }}
            >
                <h4>{HomeText.independentControl[language].header}</h4>
                <hr />
                <p>{HomeText.independentControl[language].content}</p>
            </HomeSectionImageLeft>
            <HomeSectionImageRight
                img={{ source: Surge_Protection, alt: HomeText.surgeProtection[language].header }}
            >
                <h4>{HomeText.surgeProtection[language].header}</h4>
                <hr />
                <p>{HomeText.surgeProtection[language].content}</p>
            </HomeSectionImageRight>
            <HomeSectionImageLeft
                img={{ source: App_Interface_Control, alt: HomeText.aSingleTapIsAllItTakes[language].header, style: { borderRadius: '75px' } }}
            >
                <h4>{HomeText.aSingleTapIsAllItTakes[language].header}</h4>
                <hr />
                <p>{HomeText.aSingleTapIsAllItTakes[language].content}</p>
            </HomeSectionImageLeft>
            <HomeSectionImageRight
                img={{ source: App_Interface_Connect, alt: HomeText.simpleSetupwithFreeApp[language].header }}
            >
                <h4>{HomeText.simpleSetupwithFreeApp[language].header}</h4>
                <hr />
                <p>{HomeText.simpleSetupwithFreeApp[language].content}</p>
            </HomeSectionImageRight>
            <div className="how-it-work">
                <div className="container">
                    <div className="row justify-content-center text-center">
                        <div className="col">
                            <h1>{HomeText.howItWork[language].heading}</h1>
                            <hr style={{ width: '21rem' }} />
                        </div>
                    </div>
                    <div className="row">
                        <HowItWorkItem
                            img={Easy_Installation}
                            title={HomeText.howItWork[language].easyInstallation.header}
                            content={HomeText.howItWork[language].easyInstallation.content}
                            buttonText={HomeText.howItWork[language].easyInstallation.buttonText}
                            dialogId="easyInstallation"
                        />
                        <HowItWorkItem
                            img={Schedule_Of_Power}
                            title={HomeText.howItWork[language].scheduleOfPower.header}
                            content={HomeText.howItWork[language].scheduleOfPower.content}
                            buttonText={HomeText.howItWork[language].scheduleOfPower.buttonText}
                            dialogId="scheduleOfPower"
                        />
                        <HowItWorkItem
                            img={Control_Without_Limitation}
                            title={HomeText.howItWork[language].controlWithoutLimitation.header}
                            content={HomeText.howItWork[language].controlWithoutLimitation.content}
                            buttonText={HomeText.howItWork[language].controlWithoutLimitation.buttonText}
                            dialogId="controlWithoutLimitation"
                        />
                        <HowItWorkItem
                            img={Voice_Assistant}
                            title={HomeText.howItWork[language].voiceAssistant.header}
                            content={HomeText.howItWork[language].voiceAssistant.content}
                            buttonText={HomeText.howItWork[language].voiceAssistant.buttonText}
                            dialogId="voiceAssistant"
                        />
                    </div>
                </div>
            </div>
            <EasyInstallation />
            <ScheduleOfPower />
            <VoiceAssistant />
        </div>
    )
}

export default Home