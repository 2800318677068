import React from 'react'
import { HowItWorkDialog, imageStyle, roundNumStyle } from './HowItWorkDialog'
import EasyInstallation1 from '../../images/howItWork/EasyInstallation1.png'
import EasyInstallation2 from '../../images/howItWork/EasyInstallation2.png'
import EasyInstallation3 from '../../images/howItWork/EasyInstallation3.png'
import EasyInstallation4 from '../../images/howItWork/EasyInstallation4.png'
import EasyInstallation5 from '../../images/howItWork/EasyInstallation5.png'

const EasyInstallation = () => {
    return (
        <HowItWorkDialog
            dialogId="easyInstallation"
            dialogTitle="Easy Installation"
        >
            <div className="container-fluid" style={{ width: '80%' }}>
                <div className="row">
                    <div className="col-lg">
                        <img src={EasyInstallation1} style={imageStyle} alt="Easy Installation 1" />
                        <div style={{ paddingLeft: '10%', paddingRight: '10%' }}>
                            <span style={roundNumStyle}>1</span> 登入App後按添加設備
                        </div>
                    </div>
                    <div className="col-lg">
                        <img src={EasyInstallation2} style={imageStyle} alt="Easy Installation 2" />
                        <div style={{ paddingLeft: '10%', paddingRight: '10%' }}>
                            <span style={roundNumStyle}>2</span> 選擇排插
                        </div>
                    </div>
                    <div className="col-lg">
                        <img src={EasyInstallation3} style={imageStyle} alt="Easy Installation 3" />
                        <div style={{ paddingLeft: '10%', paddingRight: '10%' }}>
                            <span style={roundNumStyle}>3</span> 長按延長線上的主按鍵5秒後按確認指示燈在快閃
                        </div>
                    </div>
                    <div className="col-lg">
                        <img src={EasyInstallation4} style={imageStyle} alt="Easy Installation 4" />
                        <div style={{ paddingLeft: '10%', paddingRight: '10%' }}>
                            <span style={roundNumStyle}>4</span> 選擇自家的WiFi名字
                            <br />
                            <span style={roundNumStyle}>5</span> 輸入WiFi密碼
                            <br />
                            <span style={roundNumStyle}>6</span> 按確定
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg">
                        <img src={EasyInstallation5} style={imageStyle} alt="Easy Installation 5" />
                    </div>
                    <div className="col-lg">
                        {/* For consistent image size */}
                    </div>
                    <div className="col-lg">
                        {/* For consistent image size */}
                    </div>
                    <div className="col-lg">
                        {/* For consistent image size */}
                    </div>
                </div>
            </div>
        </HowItWorkDialog>
    )
}

export default EasyInstallation