import React from 'react'
import SD160_1 from '../../images/SD160_1.png'
import { buyUrl } from '../../config/app_config'

const buySd160a = () => {
    window.open(buyUrl.sd160, '_blank')
}

const SD160A = ({ language, text }) => {
    return (
        <div className="product-detail">
            <div>
                <div className="container pt-3">
                    <div className="row">
                        <div className="col-lg my-auto">
                            <img src={SD160_1} alt="SD160" />
                            {/* <div style={{ width: '100px', height: '100px', border: 'solid 1px' }}>Video 1</div> */}
                        </div>
                        <div className="col-lg">
                            <h3>{text[language].model}</h3>
                            <button
                                type="button"
                                className="btn btn-outline-primary mr-2"
                                onClick={buySd160a}
                            >{text[language].buy}</button>
                            <button type="button" className="btn btn-outline-info">{text[language].support}</button>
                            <hr />
                            <h4><strong>{text[language].features.title}</strong></h4>
                            <h5>{text[language].features.multiSmartOutlets.header}</h5>
                            <p>{text[language].features.multiSmartOutlets.body}</p>
                            <h5>{text[language].features.remoteControl.header}</h5>
                            <p>{text[language].features.remoteControl.body}</p>
                            <h5>{text[language].features.workswithAmazonEchoorGoogleHome.header}</h5>
                            <p>{text[language].features.workswithAmazonEchoorGoogleHome.body}</p>
                            <h5>{text[language].features.personalizedScheduling.header}</h5>
                            <p>{text[language].features.personalizedScheduling.body}</p>
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-lg-4">
                            <h1>{text[language].description.title}</h1>
                        </div>
                        <div className="col-lg-8">
                            <h5>{text[language].description.multiSmartOutlets.header}</h5>
                            <p>{text[language].description.multiSmartOutlets.body}</p>
                            <h5>{text[language].description.highEfficiency.header}</h5>
                            <p>{text[language].description.highEfficiency.body}</p>
                            <h5>{text[language].description.remoteControl.header}</h5>
                            <p>{text[language].description.remoteControl.body}</p>
                            <h5>{text[language].description.simpleSetup.header}</h5>
                            <p>{text[language].description.simpleSetup.body}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <h1>{text[language].systemRequirements.title}</h1>
                        </div>
                        <div className="col-lg-8">
                            <h5>{text[language].systemRequirements.header}</h5>
                            <ul>
                                {text[language].systemRequirements.body.map((item, i) => { return <li key={i}>{item}</li> })}
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <h1>{text[language].others.title}</h1>
                        </div>
                        <div className="col-lg-8">
                            <table className="table table-bordered table-hover">
                                {/* <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">First</th>
                                        <th scope="col">Last</th>
                                        <th scope="col">Handle</th>
                                    </tr>
                                </thead> */}
                                <tbody>
                                    <tr>
                                        <td>Material</td>
                                        <td>PC (V-0 flame-retardant)</td>
                                    </tr>
                                    <tr>
                                        <td>Size</td>
                                        <td>230mm(L) &times; 110mm(W) &times; 33mm(H)</td>
                                    </tr>
                                    <tr>
                                        <td>Color</td>
                                        <td>White</td>
                                    </tr>
                                    <tr>
                                        <td>EAN</td>
                                        <td>0655100998087</td>
                                    </tr>
                                    <tr>
                                        <td>NCC Number</td>
                                        <td>CCAI19LP1590T2</td>
                                    </tr>
                                    <tr>
                                        <td>Plug Type</td>
                                        <td>US Power Strip</td>
                                    </tr>
                                    <tr>
                                        <td>Power Input</td>
                                        <td>110-250V, 50/60Hz, 10A (Max)</td>
                                    </tr>
                                    <tr>
                                        <td>Resistive</td>
                                        <td>Single AC 10A, 4 AC 16A, 2400W (Max.)</td>
                                    </tr>
                                    <tr>
                                        <td>Certificate</td>
                                        <td>CE, RoHS, NCC</td>
                                    </tr>
                                    <tr>
                                        <td>Warranty</td>
                                        <td>12-month</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SD160A