import React, { useState } from 'react'
import {
    BrowserRouter as Router,
    Route,
    Switch
} from 'react-router-dom'
import './scss/App.scss'
import Home from './components/Home'
import Product from './components/Product'
import Contact from './components/Contact'
import Support from './components/Support'
import Warrant from './components/Warrant'
import FAQ from './components/FAQ'
import Account from './components/Account'
import Search from './components/Search'
import ShoppingCart from './components/ShoppingCart'
import Privacy from './components/Privacy'
import PageNotFound from './components/PageNotFound'
import Header from './components/Header'
import Footer from './components/Footer'
import ScrollToTop from './components/ScrollToTop'

function App() {
    const [language, setLanguage] = useState('eng')
    return (
        <Router>
            <ScrollToTop>
                <div className="App">
                    <Header setLanguage={setLanguage} />
                    {/* A <Switch> looks through its children <Route>s and renders the first one that matches the current URL. */}
                    <Switch>
                        <Route
                            exact path="/"
                            render={() => <Home language={language} setLanguage={setLanguage} />}
                        />
                        <Route
                            path="/product"
                            render={(props) => <Product {...props} language={language} />}
                        />
                        <Route path="/contact" component={Contact} />
                        <Route path="/support" component={Support} />
                        <Route path="/warrant" component={Warrant} />
                        <Route
                            path="/faq"
                            render={() => <FAQ language={language} />}
                        />
                        <Route path="/account" component={Account} />
                        <Route path="/search" component={Search} />
                        <Route path="/shoppingCart" component={ShoppingCart} />
                        <Route path="/privacy" component={Privacy} />
                        <Route component={PageNotFound} />
                    </Switch>
                    <Footer />
                </div>
            </ScrollToTop>
        </Router>
    )
}

export default App