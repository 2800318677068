import React from 'react'
import {
    withRouter
} from 'react-router-dom'
import NavLinks from './NavLinks'

const Header = ({ location, setLanguage }) => {
    if (location.pathname !== '/') {
        return (
            <header className="header fixed-top">
                <NavLinks setLanguage={setLanguage} />
            </header>
        )
    } else {
        return (<div></div>)
    }
}

export default withRouter(Header)