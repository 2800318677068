import React, { useEffect } from 'react'
import {
    Route,
    Switch,
    NavLink
} from 'react-router-dom'
import SD140A from './products/SD140A';
import SD160A from './products/SD160A';
import SD140_1 from '../images/SD140_1.png'
import SD160_1 from '../images/SD160_1.png'
import PageNotFound from './PageNotFound'
import ProductText from '../texts/ProductText.json'

import $ from 'jquery'

function changeElementSize() {
    $('.product-summary').css('min-height', `${$(window).height() - $('.header').height() - $('.footer').height()}px`)
}

function changeElementSizeInAllConditions() {
    if ($('button.navbar-toggler').is(":visible")) {
        if ($('.navbar-collapse').hasClass('collapsing')) {
            $('.navbar-collapse').on('hidden.bs.collapse', function (e) {
                changeElementSize()
            })
        } else {
            changeElementSize()
        }
    } else {
        changeElementSize()
    }
}

const Product = (props) => {
    useEffect(() => {
        changeElementSizeInAllConditions()
        $(window).on('resize', function () {
            changeElementSizeInAllConditions()
        })
        return () => {
            // console.log('clean up effect')
        }
    })
    return (
        <div>
            <Switch>
                <Route
                    exact path={`${props.match.url}`}
                    render={() => <Summary match={props.match} language={props.language} text={ProductText.summary} />}
                />
                <Route
                    exact path={`${props.match.url}/sd140a`}
                    render={() => <SD140A language={props.language} text={ProductText.sd140} />}
                />
                <Route
                    exact path={`${props.match.url}/sd160a`}
                    render={() => <SD160A language={props.language} text={ProductText.sd160} />}
                />
                <Route component={PageNotFound} />
            </Switch>
        </div>
    )
}

const Summary = ({ match, language, text }) => {
    return (
        <div className="product-summary">
            <div className="container">
                <div className="row">
                    <div className="col-auto mx-auto">
                        <div className="card mt-3">
                            <div className="row no-gutters">
                                <div className="col-5 my-auto">
                                    <img src={SD140_1} className="card-img-top" alt="SD140" />
                                </div>
                                <div className="col-7">
                                    <div className="card-body">
                                        <p className="card-text">{text[language].sd140.header}</p>
                                        <h5 className="card-title">{text[language].sd140.model}</h5>
                                        <NavLink exact to={`${match.url}/sd140a`} className="btn btn-outline-success">{text[language].sd140.buttonText}</NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-auto mx-auto">
                        <div className="card mt-3">
                            <div className="row no-gutters">
                                <div className="col-5 my-auto">
                                    <img src={SD160_1} className="card-img-top" alt="SD160" />
                                </div>
                                <div className="col-7">
                                    <div className="card-body">
                                        <p className="card-text">{text[language].sd160.header}</p>
                                        <h5 className="card-title">{text[language].sd160.model}</h5>
                                        <NavLink exact to={`${match.url}/sd160a`} className="btn btn-outline-success">{text[language].sd160.buttonText}</NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Product