import React from 'react'

const Privacy = props => (
	<div className="privacy">
        <h1 id="Privacy-Notice" style={{textAlign: 'center'}}>PRIVACY NOTICE</h1>
        <ul>
            <li><strong>Effective Date: 2018-12-18</strong></li>
            <li><strong>Updated Date: 2019-1-18</strong></li>
        </ul>
        <p>Tuya Inc., its affiliates and subsidiaries (“we”, “us”, “our”, “Tuya”) are committed to protecting your privacy.
            This Privacy Statement (“Statement”) describes our practices in connection with information privacy on personal
            data we process through your individual use of the following services, products, and related mobile applications
            (collectively, the “Products”)</p>
        <ul>
            <li>Simple Mobile Application</li>
        </ul>
        <p>In this Privacy Notice, “Personal Data” means information that can be used to identify an individual, either from
            that information alone, or from that information and other information we have access to about that individual.
            “Smart Devices” refers to those nonstandard computing devices produced or manufactured by hardware
            manufacturers, with human-machine interface and the ability to transmit data that connect wirelessly to a
            network, including: smart home appliances, smart wearable devices, smart air cleaning devices, etc. “Apps”
            refers to those mobile applications developed by Tuya that provide end users remote control to Smart Devices and
            with the ability to connect to Tuya IoT Platform.</p>
        <p>For other branded mobile applications powered by Tuya, our Clients control all the Personal Data collected
            through our Products. We collect the information under the direction of our Clients and the processing of such
            information shall be limited to the purpose of providing the service for which our Clients has engaged us. If
            you are a customer of one of our Clients and would no longer like to be contacted by one of our Clients that use
            our service, please contact the Client that you interact with directly.</p>
        <h2 id="What-Personal-Data-do-we-collect">What Personal Data do we collect</h2>
        <p>In order to provide our services to you, we will ask you to provide necessary personal data that is required to
            provide those services. If you do not provide your personal data, we may not be able to provide you with our
            products or services.</p>
        <h2 id="Information-You-Voluntarily-Provide-Us">Information You Voluntarily Provide Us</h2>
        <ul>
            <li><strong>Account or Profile Data</strong>: When you register an account with us, we may collect your contact
                details, such as your email address, phone number, and user name. During your interaction with our Products,
                we may further collect your nickname, profile picture, country code, language preference or time zone
                information into your account.</li>
            <li><strong>Feedback</strong>: When using feedback and suggestion features in our Products, we will collect your
                email address, mobile phone number and your feedback content to address your problems and solve device
                failures on a timely basis.</li>
        </ul>
        <h2 id="Information-We-Collect-Automatically">Information We Collect Automatically</h2>
        <ul>
            <li><strong>Device Information</strong>: When you interact with our Product, we automatically collect device
                information, such as the MAC address of your devices, IP address, wireless connection information, operating
                system type and version, application version number, push notification identifier, log files, and mobile
                network information.</li>
            <li><strong>Usage Data</strong>: During your interaction with our Sites and Services, we automatically collect
                usage data relating to visits, clicks, downloads, messages sent/received, and other usage of our Sites and
                Services.</li>
            <li><strong>Log information</strong>: When you use our app, the application and exception log may be uploaded.
            </li>
            <li><strong>Location Information</strong>: We may collect information about your real-time precise or
                non-precise geo-location when you use our specific Products or Services, such as robot cleaner and weather
                service.</li>
        </ul>
        <h2 id="Smart-Devices-Related-Information">Smart Devices Related Information</h2>
        <ul>
            <li><strong>Basic information of Smart Devices</strong>: When you connect your Smart Devices with our Products
                or Services, we may collect basic information about your Smart Devices such as device name, device ID,
                online status, activation time, firmware version, and upgrade information.</li>
            <li><strong>Information reported by Smart Devices</strong>: Depending on the different Smart Devices you elect
                to connect with our Products or Services, we may collect different information reported by your Smart
                Devices. For example, smart weights or fitness trackers may report your height, weight, body fat mass (BFM),
                BMI and skeletal muscle mass (SMM); smart cameras may report images or videos captured by it.</li>
        </ul>
        <h2 id="Purposes-and-legal-basis-for-processing-Personal-Data">Purposes and legal basis for processing Personal Data
        </h2>
        <p>The purpose for which we may process information about you are as follows:</p>
        <ul>
            <li><strong>Provide You Services</strong>: We process your account and profile data, device information, usage
                data, location information, and Smart Device related information to provide you with our Products and
                Services that you have requested or purchased. The legal basis for this processing is to perform our
                contract with you according to our Terms of Use.,</li>
            <li><strong>Improve Our Services</strong>: We process your device information, usage data, location information
                and Smart Device related information to ensure the functions and safety of our Products, to develop and
                improve our Products and Services, to analyze the efficiency of our operations, and to prevent and trace
                fraudulent or inappropriate usage. The legal basis for this processing is to perform our
                <strong>contract</strong> with you according to our Terms of Use.</li>
            <li><strong>Non-marketing Communication</strong>: We process your personal data to send you important
                information regarding the Services, changes to our terms, conditions, and policies and/or other
                administrative information. Because this information may be important, you may not opt-out of receiving such
                communications. The legal basis for this processing is to perform our <strong>contract</strong> with you
                according to our Terms of Use.</li>
            <li><strong>Marketing Communication</strong>: We may process your personal data to provide marketing and
                promotional materials to you on our Products and Services. If we do so, each communication we send you will
                contain instructions permitting you to opt-out of receiving future communications of that nature. The legal
                basis for this processing is your <strong>consent</strong>. Additionally, if you consent to participate in
                our lottery, contest or other promotions, we may use your personal data to manage such activities.</li>
            <li><strong>Personalization</strong>: We may process your account and profile data, usage data, device
                information to personalize product design and to provide you with services tailored for you, such as
                recommending and displaying information and advertisements regarding products suited to you, and to invite
                you to participate in surveys relating to your use of our Products. The legal basis for this processing is
                your <strong>consent</strong>.</li>
            <li><strong>Legal Compliance</strong>: We may process your personal data as we believe to be necessary or
                appropriate: (a) to comply with applicable laws and regulations; (b) to comply with legal process; © to
                respond to requests from public and government authorities (d) to enforce our terms and conditions; (e) to
                protect our operations, business and systems; (f) to protect our rights, privacy, safety or property, and/or
                that of other users, including you; and (g) to allow us to pursue available remedies or limit the damages
                that we may sustain.</li>
        </ul>
        <h2 id="Who-do-we-share-Personal-Data-with">Who do we share Personal Data with?</h2>
        <p>At Tuya, we only share personal data in ways that we tell you about.</p>
        <p>We may share your Personal Data with the following recipients:</p>
        <ul>
            <li>To our third party service providers who perform certain business-related functions for us, such as website
                hosting, data analysis, payment and credit card processing, infrastructure provision, IT services, customer
                support service, e-mail delivery services, and other similar services to enable them to provide services to
                us.</li>
            <li>To our customers and other business partners who provide you, directly or indirectly, with your Smart
                Devices, and/or networks and systems through which you access and use our Sites and Services.</li>
            <li>To an affiliate or other third party in the event of any reorganization, merger, sale, joint venture,
                assignment, transfer or other disposition of all or any portion of our business, assets or stock (including
                without limitation in connection with any bankruptcy or similar proceedings). In such an event, you will be
                notified via email and/or a prominent notice on our website of any change in ownership, incompatible new
                uses of your personal information, and choices you may have regarding your personal information.</li>
            <li>As we believe to be necessary or appropriate: (a) to comply with applicable laws and regulations; (b) to
                comply with legal process; © to respond to requests from public and government authorities, including public
                and government authorities outside your country of residence; (d) to enforce our terms and conditions; (e)
                to protect our operations, business and systems; (f) to protect our rights, privacy, safety or property,
                and/or that of other users, including you; and (g) to allow us to pursue available remedies or limit the
                damages that we may sustain.</li>
            <li>To subsidiaries or affiliates within our corporate family, to carry out regular business activities.</li>
        </ul>
        <p>Except for the third parties described above, to third parties only with your consent.</p>
        <h2 id="Your-Rights-Relating-to-Your-Personal-Data">Your Rights Relating to Your Personal Data</h2>
        <p>We respect your rights and control over your personal data. You may exercise any of the following rights:</p>
        <ul>
            <li>Via the “Profile - Personal Center” in our Products (for Product version 3.2 and later)</li>
            <li>By emailing us at <a href="mailto:privacy@tuya.com" target="_blank" rel="noopener noreferrer">privacy@tuya.com</a> (for
                Product version before 3.2)</li>
        </ul>
        <p>You do not have to pay a fee and we will aim to respond you within 30 days. If you decide to email us, in your
            request, please make clear what information you would like to have changed, whether you would like to have your
            personal information deleted from our database or otherwise let us know what limitations you would like to put
            on our use of your personal information. Please note that we may ask you to verify your identity before taking
            further action on your request, for security purposes.</p>
        <p>You may:</p>
        <ul>
            <li>Request access to the personal data that we process about you;</li>
            <li>Request that we correct inaccurate or incomplete personal information about you;</li>
            <li>Request deletion of personal data about you;</li>
            <li>Request restrictions, temporarily or permanently, on our processing of some or all personal data about you;
            </li>
            <li>Request transfer of personal information to you or a third party where we process the data based on your
                consent or a contract with you, and where our processing is automated; and</li>
            <li>Opt-out or object to our use of personal data about you where our use is based on your consent or our
                legitimate interests.</li>
        </ul>
        <h2 id="Information-Security-Safeguards">Information Security Safeguards</h2>
        <p>We use commercially reasonable physical, administrative, and technical safeguards to preserve the integrity and
            security of your personal information. Tuya provides various security strategies to effectively ensure data
            security of user and device. As for device access, Tuya proprietary algorithms are employed to ensure data
            isolation, access authentication, applying for authorization. As for data communication, communication using
            security algorithms and transmission encryption protocols and commercial level information encryption
            transmission based on dynamic keys are supported. As for data processing, strict data filtering and validation
            and complete data audit are applied. As for data storage, all confidential information of users will be safely
            encrypted for storage. If you have reason to believe that your interaction with us is no longer secure (for
            example, if you feel that the security of any account you might have with us has been compromised), you must
            immediately notify us of the problem by <a href="mailto:privacy@tuya.com" target="_blank"
                rel="noopener noreferrer">privacy@tuya.com</a>.</p>
        <h2 id="Information-Retention-Period">Information Retention Period</h2>
        <p>We process your personal data for the minimum period necessary for the purposes set out in this Privacy Notice,
            unless there is a specific legal requirement for us to keep the data for a longer retention period. We determine
            the appropriate retention period based on the amount, nature, and sensitivity of your personal data, and after
            the retention period ends, we will destruct your personal data. When we are unable to do so for technical
            reasons, we will ensure that appropriate measures are put in place to prevent any further such use of your
            personal data.</p>
        <h2 id="Changes-to-this-Policy-Notice">Changes to this Policy Notice</h2>
        <p>We may update this Privacy Notice to reflect changes to our information practices. If we make any material
            changes we will notify you by email (send to the e-mail address specified in your account) or by means of a
            notice in the mobile applications prior to the change becoming effective. We encourage you to periodically
            review this page for the latest information on our privacy practices.</p>
        <h2 id="Legal-application-and-jurisdiction">Legal application and jurisdiction</h2>
        <p>This policy is formulated in accordance with the laws of the People’s Republic of China and shall be governed by
            the laws of the People’s Republic of China.</p>
        <p>If you have any questions about our practices or this Privacy Notice, please contact us as follows:</p>
        <p>Tuya Inc.</p>
        <p>Postal Mailing Address: 75 E Santa Clara St, 6th Floor, San Jose, CA 95113 <strong>or</strong></p>
        <p>5, 6, 7, 8 and 11th Floor, No. 3 Building, More Centre, 87 Gudun Road, Xihu District, HangZhou, China.</p>
        <p>Email: <a href="mailto:privacy@tuya.com" target="_blank" rel="noopener noreferrer">Privacy@tuya.com</a>.</p>
    </div>
)

export default Privacy