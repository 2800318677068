import React, { useEffect } from 'react'
import $ from 'jquery'
import { NavLink } from 'react-router-dom'
import { ReactComponent as SimpleLogo } from '../images/simple_logo.svg'
import AccountCircle from '@material-ui/icons/AccountCircle';
import Search from '@material-ui/icons/Search';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import Translate from '@material-ui/icons/Translate'

function NavLinks({ page, setLanguage }) {
    useEffect(() => {
        $('.clickable').on('click', function () {
            $('.navbar-collapse').collapse('hide')
        })
        return () => {
            // console.log('clean up effect')
        }
    })

    return (
        <nav id={page} className={`navbar navbar-expand-lg navbar-dark ${page === 'home' ? 'bg-transparent' : 'bg-dark'}`}>
            <a className="navbar-brand" href="/"><SimpleLogo width={165} /></a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#mainNavBar" aria-controls="mainNavBar" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="mainNavBar">
                <div className="navbar-nav ml-auto">
                    <NavLink exact to="/" className="nav-link clickable">Home</NavLink>
                    <NavLink to="/product" className="nav-link clickable">Product</NavLink>
                    <NavLink to="/contact" className="nav-link clickable">Contact</NavLink>
                    <NavLink to="/support" className="nav-link clickable">Support</NavLink>
                    <NavLink to="/warrant" className="nav-link clickable">Warrant</NavLink>
                    <NavLink to="/faq" className="nav-link clickable">FAQ</NavLink>
                    <NavLink to="/account" className="nav-link clickable" is-icon="true">
                        <AccountCircle className="icon" />
                    </NavLink>
                    <NavLink to="/search" className="nav-link clickable" is-icon="true">
                        <Search className="icon" />
                    </NavLink>
                    <NavLink to="/shoppingCart" className="nav-link clickable" is-icon="true">
                        <ShoppingCart className="icon" />
                    </NavLink>
                    <div className="dropdown">
                        <button className="nav-link btn btn-secondary dropdown-toggle bg-transparent border-0" type="button" id="translateDropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ><Translate className="icon" /></button>
                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="translateDropdownMenuButton">
                            <button className="dropdown-item clickable" onClick={() => setLanguage('eng')}>English</button>
                            <button className="dropdown-item clickable" onClick={() => setLanguage('chi')}>中文</button>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default NavLinks