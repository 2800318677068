import 'bootstrap/dist/css/bootstrap.min.css'
import $ from 'jquery'
import 'popper.js'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import React from 'react'
import ReactDOM from 'react-dom'
import './pace.css'
import App from './App'
import * as serviceWorker from './serviceWorker'

ReactDOM.render(<App />, document.getElementById('root'))

function changeHeaderBg(target) {
    if ($(target).scrollTop() > 0) {
        $('#home.navbar').removeClass('bg-transparent')
        $('#home.navbar').addClass('bg-dark')
        $('.navbar').addClass('solid')
    } else {
        $('#home.navbar').removeClass('bg-dark')
        $('#home.navbar').addClass('bg-transparent')
        $('.navbar').removeClass('solid')
    }
}

$(document).ready(function () {
    /* prevent fixed-top navbar overlap with body content as it pulled from the normal flow of the DOM */
    $('body').css('padding-top', `${$('.header').height()}px`)
    /* update header background when scrolling */
    changeHeaderBg($(window))
    $(window).scroll(function () {
        changeHeaderBg($(window))
    })
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()