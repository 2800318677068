import React from 'react'
import { HowItWorkDialog, imageStyle, roundNumStyle } from './HowItWorkDialog'
import VoiceAssistant_Google1 from '../../images/howItWork/VoiceAssistant_Google1.png'
import VoiceAssistant_Google2 from '../../images/howItWork/VoiceAssistant_Google2.png'
import VoiceAssistant_Google3 from '../../images/howItWork/VoiceAssistant_Google3.png'
import VoiceAssistant_Google4 from '../../images/howItWork/VoiceAssistant_Google4.png'
import VoiceAssistant_Google5 from '../../images/howItWork/VoiceAssistant_Google5.png'
import VoiceAssistant_Google6 from '../../images/howItWork/VoiceAssistant_Google6.png'
import VoiceAssistant_Google7 from '../../images/howItWork/VoiceAssistant_Google7.png'
import VoiceAssistant_Google8 from '../../images/howItWork/VoiceAssistant_Google8.png'
import VoiceAssistant_Google9 from '../../images/howItWork/VoiceAssistant_Google9.png'
import VoiceAssistant_Google10 from '../../images/howItWork/VoiceAssistant_Google10.png'

const VoiceAssistant = () => {
    return (
        <HowItWorkDialog
            dialogId="voiceAssistant"
            dialogTitle="Voice Assistant"
        >
            <div className="container-fluid" style={{ width: '80%' }}>
                <h3 className="text-center">Google Assistant</h3>
                <div className="row">
                    <div className="col-lg">
                        <img src={VoiceAssistant_Google1} style={imageStyle} alt="Voice Assistant - Google 1" />
                        <div style={{ paddingLeft: '10%', paddingRight: '10%' }}>
                            <span style={roundNumStyle}>1</span> 開啟Google Home app後按新增按鈕
                        </div>
                    </div>
                    <div className="col-lg">
                        <img src={VoiceAssistant_Google2} style={imageStyle} alt="Voice Assistant - Google 2" />
                        <div style={{ paddingLeft: '10%', paddingRight: '10%' }}>
                            <span style={roundNumStyle}>2</span> 按下設定裝置
                        </div>
                    </div>
                    <div className="col-lg">
                        <img src={VoiceAssistant_Google3} style={imageStyle} alt="Voice Assistant - Google 3" />
                        <div style={{ paddingLeft: '10%', paddingRight: '10%' }}>
                            <span style={roundNumStyle}>3</span> 按下是否有已經設定好的裝置
                        </div>
                    </div>
                    <div className="col-lg">
                        <img src={VoiceAssistant_Google4} style={imageStyle} alt="Voice Assistant - Google 4" />
                        <div style={{ paddingLeft: '10%', paddingRight: '10%' }}>
                            <span style={roundNumStyle}>4</span> 找出Smart Life的app
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg">
                        <img src={VoiceAssistant_Google5} style={imageStyle} alt="Voice Assistant - Google 5" />
                        <div style={{ paddingLeft: '10%', paddingRight: '10%' }}>
                            <span style={roundNumStyle}>5</span> 輸入帳號及密碼
                            <br />
                            <span style={roundNumStyle}>6</span> 按下Link Now按鈕
                        </div>
                    </div>
                    <div className="col-lg">
                        <img src={VoiceAssistant_Google6} style={imageStyle} alt="Voice Assistant - Google 6" />
                        <div style={{ paddingLeft: '10%', paddingRight: '10%' }}>
                            <span style={roundNumStyle}>7</span> 按下Authorize按鈕
                        </div>
                    </div>
                    <div className="col-lg">
                        <img src={VoiceAssistant_Google7} style={imageStyle} alt="Voice Assistant - Google 7" />
                        <div style={{ paddingLeft: '10%', paddingRight: '10%' }}>
                            <span style={roundNumStyle}>8</span> 選擇Smart Life
                            <br />
                            <span style={roundNumStyle}>9</span> 按下Link Now
                        </div>
                    </div>
                    <div className="col-lg">
                        <img src={VoiceAssistant_Google8} style={imageStyle} alt="Voice Assistant - Google 8" />
                        <div style={{ paddingLeft: '10%', paddingRight: '10%' }}>
                            <span style={roundNumStyle}>10</span> 選擇端口
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg">
                        <img src={VoiceAssistant_Google9} style={imageStyle} alt="Voice Assistant - Google 9" />
                        <div style={{ paddingLeft: '10%', paddingRight: '10%' }}>
                            <span style={roundNumStyle}>11</span> 分配位置
                        </div>
                    </div>
                    <div className="col-lg">
                        <img src={VoiceAssistant_Google10} style={imageStyle} alt="Voice Assistant - Google 10" />
                        <div style={{ paddingLeft: '10%', paddingRight: '10%' }}>
                            <span style={roundNumStyle}>12</span> 完成後會在Google Home app主頁看見端口位置
                        </div>
                    </div>
                    <div className="col-lg">
                        {/* For consistent image size */}
                    </div>
                    <div className="col-lg">
                        {/* For consistent image size */}
                    </div>
                </div>
            </div>
        </HowItWorkDialog>
    )
}

export default VoiceAssistant
