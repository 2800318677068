import React, { useEffect } from 'react'
import $ from 'jquery'
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined'
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined'
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined'
import ExploreOutlinedIcon from '@material-ui/icons/ExploreOutlined'

function changeElementSize() {
    $('.contact').css('min-height', `${$(window).height() - $('.header').height() - $('.footer').height() - 100}px`)
}

function changeElementSizeInAllConditions() {
    if ($('button.navbar-toggler').is(":visible")) {
        if ($('.navbar-collapse').hasClass('collapsing')) {
            $('.navbar-collapse').on('hidden.bs.collapse', function (e) {
                changeElementSize()
            })
        } else {
            changeElementSize()
        }
    } else {
        changeElementSize()
    }
}

const Contact = () => {
    useEffect(() => {
        changeElementSizeInAllConditions()
        $(window).on('resize', function () {
            changeElementSizeInAllConditions()
        })
        return () => {
            // console.log('clean up effect')
        }
    })
    return (
        <div className="contact" style={{ margin: '50px 10%' }}>
            <h1 className="text-center" style={{ fontSize: '3em', paddingBottom: '10%' }}>Contact Us</h1>
            <div className="shadow bg-light rounded" style={{ padding: '30px 20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <h2>漢華網際有限公司</h2>
                <h5 className="text-monospace">HAN HUA TW CO.,LTD</h5>
                <br />
                <h2>蔡尚穎 <span style={{ fontSize: '0.8em' }}>經理</span></h2>
                <h5 className="text-monospace">0970-558-560</h5>

                <hr style={{ width: '60%', marginTop: 25, marginBottom: 25 }} />

                <div className="text-left">
                    <p><RoomOutlinedIcon /><span className="px-1">桃園市龜山區文三一街<span className="text-monospace">236</span>號</span></p>
                    <p><PhoneOutlinedIcon /><span className="px-1 text-monospace">(03)211-5617</span></p>
                    <p><EmailOutlinedIcon /><a className="px-1 text-monospace" href="mailto:b950782@hotmail.com">b950782@hotmail.com</a></p>
                    <p><ExploreOutlinedIcon /><span className="px-1">統編: <span className="text-monospace">50922851</span></span></p>
                </div>
            </div>
        </div>
    )
}

export default Contact