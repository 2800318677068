import React, { useEffect } from 'react'
import $ from 'jquery'

const headerStyle = {
    backgroundColor: '#4cbcf0',
    color: '#ffffff'
}

function changeElementSize() {
    $('.modal-content').css('min-height', `${$(window).height() - 20}px`)
    $('.modal-content').css('max-height', `${$(window).height() - 20}px`)

    $('.modal-body').css('max-height', `${$(window).height() - 20}px`)
    $('.modal-body').css('overflow', 'auto')
}

const HowItWorkDialog = ({ dialogId, dialogTitle, children }) => {
    useEffect(() => {
        changeElementSize()
        $(window).on('resize', function () {
            changeElementSize()
        })
        return () => { }
    })
    return (
        <div className="modal" id={dialogId} tabIndex="-1" role="dialog" aria-labelledby={`${dialogId}Title`} aria-hidden="true">
            <div className="modal-dialog" style={{ maxWidth: '100%', margin: '10px' }} role="document">
                <div className="modal-content">
                    <div className="modal-header" style={headerStyle}>
                        <h5 className="modal-title" id={`${dialogId}Title`}>{dialogTitle}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {children}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const imageStyle = {
    maxWidth: '100%',
    maxHeight: '100%'
}

const roundNumStyle = {
    display: 'inline-block',
    width: '25px',
    height: '25px',
    border: 'solid 1px',
    borderColor: '#4cbcf0',
    borderRadius: '50%',
    backgroundColor: '#4cbcf0',
    color: '#ffffff',
    padding: '1px',
    textAlign: 'center'
}

export {
    HowItWorkDialog,
    imageStyle,
    roundNumStyle
}