import React, { useEffect } from 'react'
import FAQ1 from '../images/faq/FAQ1.png'
import FAQ2 from '../images/faq/FAQ2.png'
import FAQ3 from '../images/faq/FAQ3.png'
import FAQ4 from '../images/faq/FAQ4.png'
import FAQ5 from '../images/faq/FAQ5.png'
import FAQ6 from '../images/faq/FAQ6.png'
import FAQ7 from '../images/faq/FAQ7.png'
import $ from 'jquery'

function changeElementSize() {
    $('.faq').css('min-height', `${$(window).height() - $('.header').height() - $('.footer').height()}px`)
    $('.faq').css('max-height', `${$(window).height() - $('.header').height() - $('.footer').height()}px`)
}

function changeElementSizeInAllConditions() {
    if ($('button.navbar-toggler').is(":visible")) {
        if ($('.navbar-collapse').hasClass('collapsing')) {
            $('.navbar-collapse').on('hidden.bs.collapse', function (e) {
                changeElementSize()
            })
        } else {
            changeElementSize()
        }
    } else {
        changeElementSize()
    }
}

function changeHeaderBg(target) {
    if ($(target).scrollTop() > 0) {
        $('.navbar').addClass('solid')
    } else {
        $('.navbar').removeClass('solid')
    }
}

const FAQ = ({ language }) => {
    useEffect(() => {
        /* update header background when scrolling */
        changeHeaderBg($('.faq'))
        $('.faq').scroll(function () {
            changeHeaderBg(this)
        })

        /* change element size */
        changeElementSizeInAllConditions()
        $(window).on('resize', function () {
            changeElementSizeInAllConditions()
        })
        return () => {
            // console.log('clean up effect in FAQ')
        }
    })

    const backToTop = (e) => {
        $('.faq')[0].scrollTop = 0
    }

    return (
        <div className="faq">
            {language === 'eng' ? <FaqEng /> : <FaqChi />}
            <div className="nav justify-content-end py-2">
                <button
                    onClick={backToTop}
                    type="button"
                    className="btn btn-outline-secondary">{language === 'eng' ? 'Back To Top' : '回到最上'}
                </button>
            </div>
        </div>
    )
}

const FaqEng = () => (
    <div>
        <h3>Connecting your device</h3>
        <h4>How to connect</h4>
        <strong>Step 1: Add device</strong>
        <ol>
            <li>Connect your device to the power source and turn it on</li>
            <li>Open the App, select “+” on the top right of the home page to add device. At the net-pairing page, select
                the type of product</li>
            <li>Make sure the device is in net-pairing status: the Wi-Fi indicator light blinks quickly (twice a second)
            </li>
        </ol>
        <ul>
            <li>Please refer to the operation instructions found above the “Confirm indicator light blinks rapidly” button
                on “how to make the indicator light blinks rapidly”.</li>
            <li>Please press and hold the power button for 5 seconds to reset the device if the product doesn’t have a reset
                button;</li>
            <li>For devices such as the light which do not have a switch, please connect it to the light holder and operate
                the switch 3 times (on-off-on-off-on), stop at a light on condition in which the light blinks quickly;</li>
            <li>If the product has a reset button, please press the reset button directly.</li>
            <li>It is recommended to check the operation manual in order to connect the device to the network quickly.</li>
        </ul>
        <hr />
        <strong>Step 2: Enter Wi-Fi Password</strong>
        <p>
            Select the Wi-Fi through which the device can connect to the Internet, and enter the Wi-Fi password. Then click
            “OK” to proceed to net-pairing. Only 2.4G Wi-Fi is supported at this moment, please check if the Wi-Fi network
            is 2.4G.<br />
            Finally, wait a few seconds until it displays that network has successfully been paired.
        </p>
        <hr />
        <h4>Troubleshoot when fail to connect the network or fail to add devices</h4>
        <ol>
            <li>
                <p>Make sure the device has been connected to the power source and turned on.</p>
            </li>
            <li>
                <p>Make sure the device has been in net-pairing status.</p>
            </li>
            <li>
                <p>
                    Make sure that the device, the phone and the router are close to each other.<br />
                    <img src={FAQ1} alt="FAQ 1" />
                </p>
            </li>
            <li>
                <p>Make sure that the device, the phone and the router are close to each other.<br />
                    <img src={FAQ2} alt="FAQ 2" />
                </p>
            </li>
            <li>
                <p>Make sure the router password is correct.</p>
            </li>
            <li>
                <p>Make sure the device has been added using 2.4G WiFi band. Enable the Wi-Fi broadcast function and do not
                    set it to hidden mode.<br />
                    <img src={FAQ3} alt="FAQ 3" />
                </p>
            </li>
            <li>
                <p>Make sure that the encryption method of the router’s wireless setting is WPA2-PSK and the authentication
                    type is AES, or both are set as automatic. 11nonly is not allowed in wireless mode.<br />
                    <img src={FAQ4} alt="FAQ 4" />
                </p>
            </li>
            <li>
                <p>Make sure the Wi-Fi name does not contain any Chinese characters.<br />
                    <img src={FAQ5} alt="FAQ 5" />
                </p>
            </li>
            <li>
                <p>If the number of the router’s connected devices reaches the limit, try to turn off some device’s Wi-Fi
                    functions and configure it again.<br />
                    <img src={FAQ6} alt="FAQ 6" />
                </p>
            </li>
            <li>
                <p>If the router has enabled the wireless MAC address filter, try to remove the device from the router’s MAC
                    filter list to ensure the device is not prohibited by the router to connect to the network<br />
                    <img src={FAQ7} alt="FAQ 7" />
                </p>
            </li>
        </ol>
        <h4>What if the device is offline</h4>
        <ol>
            <li>Please make sure the device has been powered on;</li>
            <li>Please check if the network is stable. Checking method: put a mobile phone or an iPad next to the device and
                make sure they are connected to the same network, then try to open a web page;</li>
            <li>Please make sure the home Wi-Fi network is working well, or whether the Wi-Fi name, password, etc have been
                modified. If so, reset the device and add it again. (How to remove the device: Open the App and enter the
                device control page, click “…” button on the top right hand corner, click on the bottom of the page “Remove
                device”)</li>
            <li>Please try to reboot the router and wait 3 minutes to observe the status of the device;</li>
            <li>If the network is in good condition and shows no sign of modifications after troubleshooting, please go to
                the device’s control page and click the “…” button in the upper right hand corner to get into the “More”
                page. Then, click “Feedback” and enter the issue you encountered and provide your Router model, we will
                handle it ASAP.</li>
        </ol>
        <hr />
        <h4>App-related</h4>
        <p>
            The app is not able to control the device remotely, the function not effective after control.<br />
            The app couldn’t control the device suddenly. It might be the result of a bad network environment or device is
            offline.
        </p>
        <ol>
            <li>Please make sure the device has been powered on;</li>
            <li>It is recommended that you keep the App open for about 3 minutes, and see whether the device is still
                online.</li>
            <li>If it’s still online, please place the phone next to the device and make sure they are connected to the same
                Wi-Fi. Try to open a web page to check for the network’s status.</li>
            <li>If device is online and network is in good condition, yet it still cannot control any device, please go to
                the control page of the device, click on the “…” button in the upper right hand corner, and then click on
                the “Send Feedback” to enter the issue you encountered, and provide your Router model, we will handle it
                ASAP.<br />
                When the device is connected, it can be controlled at the same location, but cannot be remotely controlled
                by the phone when moved to another location or other network.<br />
                If the device encounters such a situation, it may be because the device and the mobile phone are connected
                to the same LAN, but are not connected to the Internet.</li>
        </ol>
        <ul>
            <li>Please place the phone next to the device and connect it to the same Wi-Fi as the device. Try to open a web
                page to check if the Wi-Fi can connect to the Internet;</li>
            <li>If you cannot access the network, it is recommended to restart the router.</li>
            <li>If the device has been connected to the network and the above issue occurs, please go to the control page of
                the device, click on the “…” button in the upper right hand corner, and then click on the “Send Feedback” to
                enter the issue you encountered, and provide your Router model, we will handle it ASAP.<br />
                Device status cannot be synchronized with the status displayed on the App</li>
        </ul>
        <ol>
            <li>If the device is controlled by a physical switch, the device may not be synchronized at the same time.</li>
            <li>It is recommended that you open the App and the device status will be updated after operating.<br />
                The lights turns on suddenly when the power reconnects after powering off.<br />
                This may happen on lights that have no switch button (not a smart switch and no scene linkage at home). If
                the device needs to connect to the network, it is necessary to ensure that the physical switch of the lamp
                is open, and then the lamp can be controlled with the App. However, when it is powered off, the lights and
                the App will lose connection, so the light turns into an ordinary device. When the power is reconnected, if
                the light’s physical switch has been turned “on” before, then the light will turn on automatically.<br />
                How to cancel the countdown setting?<br />
                Please set the countdown timer at 0 hours and 0 minutes to cancel the countdown.<br />
                How do I switch between Celsius and Fahrenheit?<br />
                Please go to the App – “Personal Settings”, click on your profile picture to enter into the basic
                information page. At “Temperature unit”, choose the unit you need. After changing, the scene and others will
                perform and show the temperature in the unit you chose.<br />
                What if net-pairing in compatible mode fails to find SmarLife-xxxx?<br />
                You may find the undefined-xxxx in the Wi-Fi list, or other similar hotspots to connect.<br />
                What is a local scene?<br />
                ZigBee Scene Switch uses special device connection methods to create local scenes that support offline
                execution, so only ZigBee devices under the same gateway can be added.</li>
        </ol>
        <hr />
        <h3>Third-party control</h3>
        <p>
            How to check if a third party control is supported on my device?<br />
            Please add the device into the App, and go to the Settings page. Select the “…” button on the top right, you
            will see “Supported Third-party Control”, click the icon to find the quick guide for using third party
            control.<br />
            What if the third party device is connected but unable to perform voice control?<br />
            Please check the user manual of the third party control device app and see if this voice command is recognized
            accurately.
        </p>
    </div>
)

const FaqChi = () => (
    <div>
        <h3>設備聯網</h3>
        <h4>怎麼聯網</h4>
        <strong>第一步：添加設備</strong>
        <ol>
            <li>請先給設備通電，並開啟</li>
            <li>進入 App 後，在首頁點擊右上方“+”添加設備，進入配網頁面，選擇相應的產品品類</li>
            <li>將設備置於配網狀態：Wi-Fi 指示燈快速閃爍（每秒閃爍2次）<br />
                在“確認指示燈在快閃”按鈕上方有“如何將指示燈設置為快閃”的操作說明，請您參考操作。<br />
                如果像插座等有開關但是沒復位鍵的產品的話一般長按開關5秒；<br />
                如果是燈之類自己沒有開關的話，連接到燈座上，操作“開-關-開-關-開” 3次，並停留到開燈狀態，看到燈快閃即可；<br />
                如果是有復位鍵的產品，一般就是按復位鍵就可以。<br />
                建議您查看設備說明書可以更加快速將設備置於配網狀態。</li>
        </ol>
        <hr />
        <strong>第二步：輸入Wi-Fi密碼</strong>
        <p>
            選擇設備可連接互聯⽹的 Wi-Fi，輸入 Wi-Fi 密碼，點擊“確定”進入配網過程。現只支持2.4G的Wi-Fi進行配網，請檢查該Wi-Fi是否是2.4G網絡。<br />
            最後，稍等幾秒，顯示配網成功即可。
        </p>
        <hr />
        <h4>聯網失敗（添加設備失敗）怎麼排查</h4>
        <p>
            1.確保設備通電並開機。<br />
            2.確保設備處於待配網狀態。<br />
            3.確保設備、手機、路由器三者靠近。
        </p>
        <p>
            <img src={FAQ1} alt="FAQ 1" />
        </p>
        <p>4.確保路由器、手機網絡暢通。<br />
            <img src={FAQ2} alt="FAQ 2" />
        </p>
        <p>5.確保輸入的路由器密碼正確。<br />
            6.確保使用 2.4G 的 Wi-Fi 頻段添加設備，Wi-Fi 需要開啟廣播，不可設置為隱藏。<br />
            <img src={FAQ3} alt="FAQ 3" />
        </p>
        <p>7.確保路由器無線設置中加密方式為 WPA2-PSK 類型、認證類型為 AES，或兩者皆設置為自動。無線模式不能為 11n only。<br />
            <img src={FAQ4} alt="FAQ 4" />
        </p>
        <p>8.確保路由器 Wi-Fi 名稱未使用中文。<br />
            <img src={FAQ5} alt="FAQ 5" />
        </p>
        <p>9.若路由器接入設備量達到上限，可嘗試關閉某個設備的 Wi-Fi 功能空出通道重新配置。<br />
            <img src={FAQ6} alt="FAQ 6" />
        </p>
        <p>10.若路由器開啟無線 MAC 地址過濾，可嘗試將設備移出路由器的 MAC 過濾列表，保證路由器沒有禁止設備聯網。<br />
            <img src={FAQ7} alt="FAQ 7" />
        </p>
        <h4>設備顯示離線怎麼辦</h4>
        <p>1.請確認設備是否通電狀態；<br />
            2.請排查下設備所在網絡是否穩定，排查辦法：將手機或者Ipad置於同一個網絡，並放到設備邊上，嘗試打開網頁；<br />
            3.請確認家庭Wi-Fi網絡是否正常，或者是否修改過Wi-Fi名稱、密碼等，如果有，請重置設備並重新添加；（設備移除方式：打開App進入該設備控制頁面，點擊右上角“…”按鈕進入更多頁面，頁面下方點擊“移除設備”）<br />
            4.請嘗試重啟路由器之後，靜待3分鐘觀察設備狀態；<br />
            5.如排查後網絡正常也沒有更改過的情況，請進入該設備的控制頁面，點擊右上角“…”按鈕進入更多頁面，點擊“意見反饋”輸入您遇到的問題，並提供您的路由器型號，我們將竭誠為您服務。
        </p>
        <hr />
        <h4>APP相關</h4>
        <p>
            APP無法遠程控制設備，控制後不生效<br />
            APP突然無法控制，可能是網絡原因或者是設備已離線。
        </p>
        <ol>
            <li>請確認設備是否通電狀態；</li>
            <li>建議您保持APP打開狀態靜待3分鐘左右，看下該設備狀態是否還是正常在線狀態；</li>
            <li>如果依然在線，請將手機置於設備旁邊，然後連接跟設備相同的Wi-Fi，嘗試打開網頁看是否流暢，來判斷網絡狀況。</li>
            <li>如果設備在線，且網絡良好，但是還是無法控制，請進入該設備的控制頁面，點擊右上角“…”按鈕進入更多頁面，點擊”意見反饋”輸入您遇到的問題，並提供您的路由器型號，我們將竭誠為您服務。<br />
                設備聯網後，在同一個地點可以控制，但是手機換別的地址或者別的網絡就無法遠程控制了<br />
                如果設備遇到這樣的情況，有可能是設備和手機是連接在同一個局域網，但是未連接至網絡。</li>
            <li>請將手機置於設備旁邊，然後連接跟設備相同的Wi-Fi，嘗試打開網頁看是否可以打開，來判斷Wi-Fi是否可連接至網絡；</li>
            <li>如果無法訪問網絡，建議重啟下路由器；</li>
            <li>如果設備已連接至網絡，出現以上問題，請進入該設備的控制頁面，點擊右上角“…”按鈕進入更多頁面，點擊”意見反饋”輸入您遇到的問題，並提供您的路由器型號，我們將竭誠為您服務。<br />
                設備狀態無法與APP上顯示的狀態同步<br />
                如果用物理開關控制設備後，打開APP可能設備狀態未及時同步。<br />
                建議您打開App，操作設備後APP上的設備狀態即可更新。<br />
                燈斷電再來電後，原本關閉狀態的燈突然開啟<br />
                該情況會出現本身無開關的燈具（且家裡的開關非智能開關，未設置場景聯動），此類設備如需聯網，則需確保燈的物理開關是打開狀態，然後可以用APP去控制燈。但是當斷電後，燈和APP失去聯繫，這時候的燈就變成普通的燈，當再次來電時，這個燈之前的物理開關狀態為“開”的狀態，所以燈是開著的。<br />
                倒計時設置後要怎麼取消<br />
                請將倒計時時間設置為0小時0分鐘，即可取消倒計時。<br />
                溫度怎麼切換攝氏度和華氏度<br />
                請進入APP-個人中心，點擊個人頭像進入基本信息頁，在“溫度單位”選擇您所需要的溫度單位即可，更改後，後續設置場景等就會按照您選擇的溫度單位來執行。<br />
                兼容模式配網沒找到 SmarLife-xxxx怎麼辦？<br />
                可以尋找WiFi列表中的 undefined-xxxx 或者其他類似形式熱點進行連接。<br />
                什麼是本地場景？<br />
                ZigBee 場景開關使用了特殊的設備連接方式，建立本地場景，支持在斷網情況下執行，所以只能添加在同一網關下的ZigBee設備。</li>
        </ol>
        <hr />
        <h3>第三方控制</h3>
        <p>
            怎麼查看我的設備支持哪些第三方控制？<br />
            請先將設備添加至APP，並進入該設備控制頁，點擊右上角“…”按鈕進入更多頁面，在“支持的第三方控制”可查看到該設備支持的第三方控制，點擊對應的第三方控制的圖標，可查看該第三方控制的操作手冊。<br />
            第三方已連接但是無法語音控制怎麼辦？<br />
            請在對應第三方控制的APP，查看該條指令是否被識別準確，具體可查看第三方控制的操作說明。
        </p>
    </div>
)

export default FAQ