import React from 'react'
import { HowItWorkDialog, imageStyle, roundNumStyle } from './HowItWorkDialog'

import Timer1 from '../../images/howItWork/Timer1.png'
import Timer2 from '../../images/howItWork/Timer2.png'
import Timer3 from '../../images/howItWork/Timer3.png'
import Timer4 from '../../images/howItWork/Timer4.png'
import Countdown1 from '../../images/howItWork/Countdown1.png'
import Countdown2 from '../../images/howItWork/Countdown2.png'
import Countdown3 from '../../images/howItWork/Countdown3.png'

const ScheduleOfPower = () => {
    return (
        <HowItWorkDialog
            dialogId="scheduleOfPower"
            dialogTitle="Schedule Of Power"
        >
            <div className="container-fluid" style={{ width: '80%' }}>
                <h3 className="text-center">Timer</h3>
                <div className="row">
                    <div className="col-lg">
                        <img src={Timer1} style={imageStyle} alt="Timer 1" />
                        <div style={{ paddingLeft: '10%', paddingRight: '10%' }}>
                            <span style={roundNumStyle}>1</span> 開啟App後選擇裝置再按下Alarm
                                    </div>
                    </div>
                    <div className="col-lg">
                        <img src={Timer2} style={imageStyle} alt="Timer 2" />
                        <div style={{ paddingLeft: '10%', paddingRight: '10%' }}>
                            <span style={roundNumStyle}>2</span> 選擇所需的端口
                                    </div>
                    </div>
                    <div className="col-lg">
                        <img src={Timer3} style={imageStyle} alt="Timer 3" />
                        <div style={{ paddingLeft: '10%', paddingRight: '10%' }}>
                            <span style={roundNumStyle}>3</span> 按下添加按鈕
                                    </div>
                    </div>
                    <div className="col-lg">
                        <img src={Timer4} style={imageStyle} alt="Timer 4" />
                        <div style={{ paddingLeft: '10%', paddingRight: '10%' }}>
                            <span style={roundNumStyle}>4</span> 選擇時間
                                        <br />
                            <span style={roundNumStyle}>5</span> 按下保存
                                    </div>
                    </div>
                </div>
            </div>
            <br />
            <div className="container-fluid" style={{ width: '80%' }}>
                <h3 className="text-center">Countdown</h3>
                <div className="row">
                    <div className="col-lg">
                        <img src={Countdown1} style={imageStyle} alt="Countdown 1" />
                        <div style={{ paddingLeft: '10%', paddingRight: '10%' }}>
                            <span style={roundNumStyle}>1</span> 開啟App後選擇裝置再按下Countdown
                                    </div>
                    </div>
                    <div className="col-lg">
                        <img src={Countdown2} style={imageStyle} alt="Countdown 2" />
                        <div style={{ paddingLeft: '10%', paddingRight: '10%' }}>
                            <span style={roundNumStyle}>2</span> 選擇所需的端口
                                    </div>
                    </div>
                    <div className="col-lg">
                        <img src={Countdown3} style={imageStyle} alt="Countdown 3" />
                        <div style={{ paddingLeft: '10%', paddingRight: '10%' }}>
                            <span style={roundNumStyle}>3</span> 按下添加按鈕
                                    </div>
                    </div>
                    <div className="col-lg">
                        {/* For consistent image size (as Timer section have 4 images) */}
                    </div>
                </div>
            </div>
        </HowItWorkDialog>
    )
}

export default ScheduleOfPower