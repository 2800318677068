import React from 'react'

const Footer = () => {
    return (
        <footer className="footer bg-dark">
            <div className="copyright text-white-50">Copyright &copy; {new Date().getFullYear()} Synergy Tech (HK) Company Limited.</div>
        </footer>
    )
}

export default Footer