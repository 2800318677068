import React, { useEffect } from 'react'
import $ from 'jquery'

function changeElementSize() {
    $('.page-not-found').css('min-height', `${$(window).height() - $('.header').height() - $('.footer').height()}px`)
}

function changeElementSizeInAllConditions() {
    if ($('button.navbar-toggler').is(":visible")) {
        if ($('.navbar-collapse').hasClass('collapsing')) {
            $('.navbar-collapse').on('hidden.bs.collapse', function (e) {
                changeElementSize()
            })
        } else {
            changeElementSize()
        }
    } else {
        changeElementSize()
    }
}

const PageNotFound = () => {
    useEffect(() => {
        changeElementSizeInAllConditions()
        $(window).on('resize', function () {
            changeElementSizeInAllConditions()
        })
        return () => {
            // console.log('clean up effect')
        }
    })
    return (
        <div>
            <div className="page-not-found">
                <h1 className="text-center py-5">Page Not Found.</h1>
            </div>
        </div>
    )
}

export default PageNotFound